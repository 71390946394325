import React from "react";
import "./SocialMedia.css";
import { socialMediaLinks } from "../../portfolio";
// import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default function socialMedia(props) {
  return (
    <div className="social-media-div">
      {socialMediaLinks.map((media) => {
        return (
          <a
            href={media.link}
            className={`icon-button`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {/* <OverlayTrigger
            key={media.name}
            placement={"top"}
            // overlay={
            //   <Tooltip id={`tooltip-top`}>
            //     <strong>{media.name}</strong>
            //   </Tooltip>
            // }
          > */}
            <li className="software-skill-inline" name={media.name}>
              <span
                className="iconify"
                data-icon={media.fontAwesomeIcon}
                style={media.style}
                data-inline="false"
              ></span>
            </li>
          {/* </OverlayTrigger> */}
          </a>
        );
      })}
     </div>
  );
}

/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Umesh's Portfolio",
  description:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and highly scalable social and technical systems to create impact.",
  og: {
    title: "Umesh Kumhar Portfolio",
    type: "website",
    url: "https://umesh.pro/",
  },
};

//Home Page
const greeting = {
  title: "Umesh Kumhar",
  logo_name: "Umesh Kumhar",
  nickname: "DevOps Engineer",
  subTitle:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and highly scalable social and technical systems to create impact.",
  resumeLink:
    "https://drive.google.com/file/d/1oANxGivqX0Xn7SsrCDgUBhDyoiAMXjOI/view?usp=sharing",
  portfolio_repository: "https://github.com/umeshkumhar/",
  githubProfile: "https://github.com/umeshkumhar",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  {
    name: "Gmail",
    link: "mailto:write2umeshkumhar@gmail[dot]com",
    fontAwesomeIcon: "logos-google-gmail", // Reference https://fontawesome.com/icons/google?style=brands
    style: {
      backgroundColor: "transparent",
      fontSize: "26px",
    },
  },
  {
    name: "Github",
    link: "https://github.com/umeshkumhar",
    fontAwesomeIcon: "logos-github-icon", // Reference https://fontawesome.com/icons/github?style=brands
    style: {
      backgroundColor: "transparent",
      fontSize: "28px",
    },
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/umeshkumhar/",
    fontAwesomeIcon: "logos-linkedin-icon", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    style: {
      backgroundColor: "transparent",
      fontSize: "28px",
    },
  },
  {
    name: "Twitter",
    link: "https://twitter.com/umeshkumhar29",
    fontAwesomeIcon: "logos-twitter", // Reference https://fontawesome.com/icons/twitter?style=brands
    style: {
      backgroundColor: "transparent",
      fontSize: "28px",
    },
  },
  {
    name: "Facebook",
    link: "https://www.facebook.com/er.umeshkumhar",
    fontAwesomeIcon: "logos-facebook", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    style: {
      backgroundColor: "transparent",
      fontSize: "28px",
    },
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/umeshkumhar/",
    fontAwesomeIcon: "logos-instagram-icon", // Reference https://fontawesome.com/icons/instagram?style=brands
    style: {
      backgroundColor: "transparent",
      fontSize: "28px",
    },
  },
];

const skills = {
  data: [
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience in working on multiple Cloud Platforms / On-Premise Infrastructure",
        "⚡ Compliance Security hardening as per CIS Security Benchmarks in accordance with AWS Services, Lacework, TugBoat",
        "⚡ Build Terraform code around AWS infrastructure to maintain IaC and use Terraform Cloud to maintain state and workspaces",
        "⚡ Setup & Security Hardening for different kubernetes deployment solutions includes kops, GKE, EKS, Rancher, D2iQ konvoy, k3s, OpenShift.",
      ],
      softwareSkills: [
        {
          skillName: "GCP",
          fontAwesomeClassname: "logos-google-cloud",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "logos-aws",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Azure",
          fontAwesomeClassname: "logos-microsoft-azure",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "logos-kubernetes",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "logos-docker-icon",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "OpenShift",
          fontAwesomeClassname: "logos-openshift",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Terraform",
          fontAwesomeClassname: "logos-terraform-icon",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Rancher",
          fontAwesomeClassname: "logos-rancher-icon",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Linux",
          fontAwesomeClassname: "logos-linux-tux",
          style: {
            backgroundColor: "transparent",
          },
        },
        
        
      ],
    },
    {
      title: "Observaility & Logging Stack",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Configure end-to-end complete observability stack for production ready infrastructure ",
        "⚡ Design custom metrics/logs dashboards and service status page",
        "⚡ Migration of observability stack to Open Souce/Enterprise Tools",
      ],
      softwareSkills: [
        {
          skillName: "Prometheus",
          fontAwesomeClassname: "logos-prometheus",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Grafana",
          fontAwesomeClassname: "logos-grafana",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Datadog",
          fontAwesomeClassname: "logos-datadog",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Elasticsearch",
          fontAwesomeClassname: "logos-elasticsearch",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Pagerduty",
          fontAwesomeClassname: "logos-pagerduty-icon",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Bugsnag",
          fontAwesomeClassname: "logos-bugsnag-icon",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Beats",
          fontAwesomeClassname: "logos-beats",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Kibana",
          fontAwesomeClassname: "logos-kibana",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "CloudWatch",
          fontAwesomeClassname: "logos-aws-cloudwatch",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Sysdig",
          fontAwesomeClassname: "logos-sysdig-icon",
          style: {
            backgroundColor: "transparent",
          },
        },
      ],
    },
    {
      title: "CI/CD Pipeline & Automation",
      fileName: "FullStackImg",
      skills: [
        "⚡ Design & Architect CI/CD for better build and release cycle while ensuring security checks",
        "⚡ Configure Kubernetes & its complete ecosystem to support Gitlab CI/CD",
        "⚡ Automate & Build Slack Bots for complex manual steps using Bash scripts & GoLang",
      ],
      softwareSkills: [
        {
          skillName: "Jenkins",
          fontAwesomeClassname: "logos-jenkins",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Github-Actions",
          fontAwesomeClassname: "logos-github-actions",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Kubernetes Prow",
          fontAwesomeClassname: "logos-kubernetes",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Gitlab CI/CD",
          fontAwesomeClassname: "logos-gitlab",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "CircleCI",
          fontAwesomeClassname: "logos-circleci",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Ansible",
          fontAwesomeClassname: "logos-ansible",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Weave",
          fontAwesomeClassname: "logos-weave",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Heroku",
          fontAwesomeClassname: "logos-heroku-icon",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Bash",
          fontAwesomeClassname: "logos-bash-icon",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Golang",
          fontAwesomeClassname: "logos-gopher",
          style: {
            backgroundColor: "transparent",
          },
        },
      ],
    },
    // {
    //   title: "UI/UX Design",
    //   fileName: "DesignImg",
    //   skills: [
    //     "⚡ Designing highly attractive user interface for mobile and web applications",
    //     "⚡ Customizing logo designs and building logos from scratch",
    //     "⚡ Creating the flow of application functionalities to optimize user experience",
    //   ],
    //   softwareSkills: [
    //     {
    //       skillName: "Adobe XD",
    //       fontAwesomeClassname: "simple-icons:adobexd",
    //       style: {
    //         color: "#FF2BC2",
    //       },
    //     },
    //     {
    //       skillName: "Figma",
    //       fontAwesomeClassname: "simple-icons:figma",
    //       style: {
    //         color: "#F24E1E",
    //       },
    //     },
    //     {
    //       skillName: "Adobe Illustrator",
    //       fontAwesomeClassname: "simple-icons:adobeillustrator",
    //       style: {
    //         color: "#FF7C00",
    //       },
    //     },
    //     {
    //       skillName: "Inkscape",
    //       fontAwesomeClassname: "simple-icons:inkscape",
    //       style: {
    //         color: "#000000",
    //       },
    //     },
    //   ],
    // },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/umeshkumhar",
    },
    // {
    //   siteName: "Codechef",
    //   iconifyClassname: "simple-icons:codechef",
    //   style: {
    //     color: "#5B4638",
    //   },
    //   profileLink: "https://www.codechef.com/users/umesh",
    // },
    // {
    //   siteName: "Codeforces",
    //   iconifyClassname: "simple-icons:codeforces",
    //   style: {
    //     color: "#1F8ACB",
    //   },
    //   profileLink: "http://codeforces.com/profile/umesh",
    // },
    {
      siteName: "Hackerearth",
      iconifyClassname: "simple-icons:hackerearth",
      style: {
        color: "#323754",
      },
      profileLink: "https://www.hackerearth.com/@umeshkumhar",
    },
    // {
    //   siteName: "Kaggle",
    //   iconifyClassname: "simple-icons:kaggle",
    //   style: {
    //     color: "#20BEFF",
    //   },
    //   profileLink: "https://www.kaggle.com/umesh",
    // },
  ],
};

const degrees = {
  degrees: [
    {
      title: "University College of Engineering and Technology, Bikaner",
      subtitle: "B.Tech. in Computer Science & Engineering",
      logo_path: "cet.png",
      alt_name: "CET Bikaner",
      duration: "2013 - 2017",
      descriptions: [
        "⚡ I have studied basic software engineering subjects like DS, Algorithms, DBMS, OS, CA, AI etc.",
        "⚡ Apart from this, I have done courses on Containerisation, Data Science, Cloud Computing and Full Stack Development.",
        "⚡ I was selected for National Level Smart India Hackathon'17 to represent College and build ML based predective system",
      ],
      website_link: "http://cet-gov.ac.in/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Certified Kubernetes Administrator",
      subtitle: "- CNCF",
      logo_path: "k8s_logo.png",
      certificate_link:
        "https://www.credly.com/badges/cdbcddfa-ce9a-4807-869d-b74cb60a3562",
      alt_name: "Kuberetes",
      color_code: "#FFFFFF",
    },
    {
      title: "AWS Certified Solutions Architect – Professional",
      subtitle: "- AWS",
      logo_path: "aws_logo.png",
      certificate_link:
        "https://www.credly.com/earner/earned/badge/1bef3887-5c56-4e07-a1b9-4bef603f6850",
      alt_name: "AWS",
      color_code: "#FFFFFF",
    },
    {
      title: "Intermediate Cloud Security",
      subtitle: "- Cybrary",
      logo_path: "cybrary_logo.png",
      certificate_link:
        "https://www.linkedin.com/in/umeshkumhar",
      alt_name: "Cybrary",
      color_code: "#FFFFFF",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I have worked with many evolving startups and some well established companies as DevOps Enineer and Solution Architect. I always try to work with opensource communities to host meetups or be speaker at events, that really helps to build good network.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      experiences: [
        {
          title: "Senior DevOps Engineer",
          company: "Velotio Technologies",
          company_url: "https://www.velotio.com/",
          logo_path: "velotio_logo.png",
          duration: "Jan 2019 - PRESENT",
          location: "Pune, Maharastra",
          description:
            "I am working as Senior DevOps Engineer & Golang Developer to work on K8s Controller & CRD. The projects involve automation for release process improvements and for significantly enhancing the profits. I am currently working on Kubernetes Backup Restore Solution for Kubernetes & Openshift Platform using CRDs.",
          color: "#0879bf",
        },
        {
          title: "DevOps Engineer",
          company: "Saama Technologies",
          company_url: "https://www.saama.com/",
          logo_path: "saama_logo.png",
          duration: "Sept 2017 - Jan 2019",
          location: "Pune, Maharashtra",
          description:
            "I am working as DevOps Engineer and responsible for designing, architecting product CI/CD Pipeline to smoothen release process. I have also worked on setup Dev & QA Cloudera In-house Hadoop Cluster for Big Data Jobs and setup and configured Dev Kubernetes Cluster Workloads in On-premises Kubernetes cluster. I was involved in setting up HIPAA Compliance ready Infrastructure in AWS.",
          color: "#0879bf",
        },
      ],
    },
    // {
    //   title: "Internships",
    //   experiences: [
    //     {
    //       title: "Data Science Research Intern",
    //       company: "Delhivery Pvt. Ltd.",
    //       company_url: "https://www.delhivery.com/",
    //       logo_path: "delhivery_logo.png",
    //       duration: "May 2019 - Sept 2019",
    //       location: "Gurgaon, Haryana",
    //       description:
    //         "I have worked on project of predicting freight rates based on previous data. There were two objectives: (1) To build a forecasting engine to predict daily freight rates. (2) To embed feature in the model which can explain the seasonal major changes in freight rate based on regions and locations. I have closely worked with deep learning models in combination with statistical methods to create solution for this. At the end of internship, I had created model deployed on AWS EC2 with the use of Kafka stream jobs, ElasticSearch and PostgreSQL.",
    //       color: "#ee3c26",
    //     },
    //     {
    //       title: "Data Science Intern",
    //       company: "Intel Indexer LLC",
    //       company_url:
    //         "https://opencorporates.com/companies/us_dc/EXTUID_4170286",
    //       logo_path: "intel_logo.jpg",
    //       duration: "Nov 2018 - Dec 2018",
    //       location: "Work From Home",
    //       description:
    //         "This is financial Solution Company. I have made Supervised Learning model for the company which can perform time series analysis on Stock price data for 32 companies. I have built LSTM Neural Networks Model and trained the data of 32 companies for last 2 years. This model is also used for forecasting.",
    //       color: "#0071C5",
    //     },
    //   ],
    // },
    // {
    //   title: "Volunteerships",
    //   experiences: [
    //     {
    //       title: "Google Explore ML Facilitator",
    //       company: "Google",
    //       company_url: "https://about.google/",
    //       logo_path: "google_logo.png",
    //       duration: "June 2019 - April 2020",
    //       location: "Hyderabad, Telangana",
    //       description:
    //         "Explore Machine Learning (ML) is a Google-sponsored program for university students to get started with Machine Learning. The curriculum offers 3 tracks of ML Content (Beginner, Intermediate, Advanced) and relies on university student facilitators to train other students on campus and to build opensource projects under this program.",
    //       color: "#4285F4",
    //     },
    //     {
    //       title: "Microsoft Student Partner",
    //       company: "Microsoft",
    //       company_url: "https://www.microsoft.com/",
    //       logo_path: "microsoft_logo.png",
    //       duration: "Aug 2019 - May 2020",
    //       location: "Hyderabad, Telangana",
    //       description:
    //         "Microsoft Student Partner is a program for university students to lead the awareness and use of Cloud especially Azure tools in the development of their projects and startups. Under this program, I have organised hands on workshops and seminars to teach Cloud Computing concepts to students.",
    //       color: "#D83B01",
    //     },
    //     {
    //       title: "Mozilla Campus Captain",
    //       company: "Mozilla",
    //       company_url: "https://www.mozilla.org/",
    //       logo_path: "mozilla_logo.png",
    //       duration: "Oct 2019 - May 2020",
    //       location: "Kurnool, Andhra Pradesh",
    //       description:
    //         "My responsibility for this program was to create opensource environment in college and in the city. We have organised multiple hackathons on the problems collected by ordinary people from Kurnool city. We have build opensource community of our own college. The community is available at dsc_iiitdmk on github.",
    //       color: "#000000",
    //     },
    //     {
    //       title: "Developer Students Club Member",
    //       company: "DSC IIITDM Kurnool",
    //       company_url:
    //         "https://www.linkedin.com/company/developer-students-club-iiitdm-kurnool",
    //       logo_path: "dsc_logo.png",
    //       duration: "Jan 2018 - May 2020",
    //       location: "Kurnool, Andhra Pradesh",
    //       description:
    //         "We have well established developer club in college which is directly associated with Google Developers. We have developed many interdisciplinary projects under the membership of this club. We have organised workshops and activities on Android Application Development, Flutter and React JS.",
    //       color: "#0C9D58",
    //     },
    //     {
    //       title: "Developer Program Member",
    //       company: "Github",
    //       company_url: "https://github.com/",
    //       logo_path: "github_logo.png",
    //       duration: "July 2019 - PRESENT",
    //       location: "Work From Home",
    //       description:
    //         "I am actively contributing to many opensource projects. I have contributed to projects of organisations like Tensorflow, Uber, Facebook, Google, Scikit-learn, Kiwix, Sympy, Python, NVLabs, Fossasia, Netrack, Keras etc. These contributions include bug fixes, feature requests and formulating proper documentation for project.",
    //       color: "#181717",
    //     },
    //   ],
    // },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create Data Science projects and deploy them to web applications using cloud infrastructure.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description:
    "I have worked on and published a few research papers and publications of my own.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "umesh.jpeg",
    description:
      "I am available on almost every social media. You can connect me, I can help you with Cloud Services & Container & Virtualization World.",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "For individual fundamental empowerment, I like to write powerful lessons that create impact on each of the reader individually to grow their professional knowledge.",
    link: "https://medium.com/@umeshkumhar",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle:
      "Pune, Maharastra - 411021",
    avatar_image_path: "address_image.svg",
    location_map_link: "mailto:write2umeshkumhar@gmail.com",
  },
  phoneSection: {
    title: "Phone Number",
    subtitle: "+91 9680-208-222",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  contactPageData,
};
